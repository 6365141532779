import React from "react";
import st from "./App.module.scss";
import CookieConsent from "react-cookie-consent";

function App() {
  return (
    <div className={st.app}>
      <CookieConsent
        buttonStyle={{
          backgroundColor: "#A72F2F",
          color: "#fff",
          fontSize: "16px",
          borderRadius: 5,
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>

      <div className={st.titlePage}>
        <header className={st.header}>
          <img src="/logo.webp" className={st.logo} alt="binalert logo" />
          <h1>Enatice Ltd</h1>
          <div>
            A company registerd in England & Wales. <br />
            Company number: 15626268
          </div>
          <br />
          <a href="mailto:hello@enatice.com">hello@enatice.com</a>
          <br />
        </header>
      </div>
    </div>
  );
}

export default App;
